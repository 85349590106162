<template>
    <div class="container">
        <b-row>
            <div class="promo-image">
                <img
                    v-if="existData"
                    height="400"
                    width="600"
                    loading="lazy"
                    :alt="promotions.title"
                    :src="promotions.image"
                >
                <home-promotions-image-loader v-if="loading && !existData" />
            </div>
            <div
                v-if="existData"
                class="promo-title"
            >
                <h3>{{ promotions.title }}</h3>
            </div>
            <div
                v-if="existData"
                class="promo-descr"
            >
                <p class="lg">
                    {{ promotions.description }}
                </p>
                <router-link
                    v-slot="{ navigate }"
                    :to="promotions.link"
                    custom
                >
                    <b-button
                        variant="primary"
                        class="fill lg"
                        @click="navigate"
                    >
                        <shopping-cart-icon /><span>{{
                            $t('page.home.promotionsButton')
                        }}</span>
                    </b-button>
                </router-link>
            </div>
            <home-promotions-text-loader v-if="loading && !existData" />
        </b-row>
    </div>
</template>

<script>
    import ShoppingCartIcon from 'vue-feather-icons/icons/ShoppingCartIcon';
    import { mapState } from 'vuex';

    import HomePromotionsImageLoader from '@/components/loaders/home/HomePromotionsImageLoader';
    import HomePromotionsTextLoader from '@/components/loaders/home/HomePromotionsTextLoader';
    export default {
        name: 'HomePromotions',
        components: {
            ShoppingCartIcon,
            HomePromotionsImageLoader,
            HomePromotionsTextLoader,
        },
        computed: {
            ...mapState('home', ['promotions', 'loading']),
            existData() {
                return Object.keys(this.promotions).length !== 0;
            },
        },
    };
</script>

<style scoped lang="scss">
.promotions {
  .row {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto 1fr;
    grid-template-areas:
      'one top'
      'one bottom';
    @include media-breakpoint-down(md) {
      grid-template-columns: 100%;
      grid-template-areas:
        'top'
        'center'
        'bottom';
    }
    > div {
      &.promo-image {
        grid-area: one;
        @include media-breakpoint-down(md) {
          grid-area: center;
        }
        img {
          border-radius: 8px;
          object-fit: cover;
          width: 100%;
          height: auto;
        }
      }
      &.promo-title {
        grid-area: top;
        h3 {
          margin-bottom: 24px;
          @include media-breakpoint-down(md) {
            margin-bottom: 16px;
          }
        }
      }
      &.promo-descr {
        grid-area: bottom;
        @include media-breakpoint-down(md) {
          margin-top: 16px;
        }
      }
      &.centered-box {
        @include media-breakpoint-down(md) {
          order: 0;
        }
      }
    }
  }
}
</style>
